<template>
  <div class="sign-modal">
    <van-dialog
      v-model="modal.isShow"
      :title="modal.title"
      show-cancel-button
      @confirm="onConfirm"
      @cancel="onCancel"
    >
      <vue-esign
        v-if="modal.isShow"
        ref="esign"
        :width="800"
        :height="300"
        :isCrop="isCrop"
        :lineWidth="lineWidth"
        :lineColor="lineColor"
        :bgColor="bgColor"
      />
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  components: {},
  props: {
    value: {
      type: Object,
      default() {
        return {
          isShow: false,
          title: "",
        };
      },
    },
  },
  data() {
    return {
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
      borders: "1px solid red",
    };
  },
  computed: {
    modal: {
      get() {
        return this.value;
      },
      set() {},
    },
  },
  watch: {},
  mounted() {},
  methods: {
    // 确认事件
    onConfirm() {
      this.$refs.esign
        .generate()
        .then((res) => {
          const data = {
            type: this.modal.type ? this.modal.type : "",
            imgDataBase: res,
            file: this.base64ToFile(res),
          };
          this.$emit("confirm", data);
          this.onCancel();
        })
        .catch(() => {
          this.onCancel();
          Toast.fail("请签名不能为空");
        });
    },
    // 取消事件
    onCancel() {
      this.$refs.esign.reset();
    },
    // base64转file
    base64ToFile(base64Data) {
      const binary = window.atob(base64Data.split(",")[1]);
      const mime = base64Data.split(",")[0].match(/:(.*?);/)[1];
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      const fileData = new Blob([new Uint8Array(array)], {
        type: mime,
      });
      const file = new File([fileData], `${new Date().getTime()}.png`, {
        type: mime,
      });
      return file;
    },
  },
};
</script>

<style lang="less" scoped></style>
