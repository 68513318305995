<template>
  <div>
    <van-cell-group title="附件图片">
      <!-- 原有 -->
      <van-cell :border="false">
        <van-col
          span="8"
          class="img-box"
          v-for="(item, index) in oldFileList.filter((item) =>
            imageType.test(item.ossFileName)
          )"
          :key="index"
        >
          <van-image
            width="70"
            height="70"
            :src="`${$api.base.file}/${item.ossFileName}`"
            @click="showPreview"
          >
            <template v-if="type == 'add'">
              <div
                class="img-delete"
                @click.stop="oldFileDelete(item.id, index)"
              >
                删除
              </div>
            </template>
          </van-image>
          <!-- <van-field
            v-model="item.fileName"
            placeholder=""
            maxlength="20"
            style="flex-base: calc(100% - 70px)"
            :disabled="true"
          /> -->
        </van-col>
      </van-cell>
      <van-cell
        v-for="(items, index) in oldFileList.filter((item) =>
          fileType.test(item.fileName)
        )"
        :key="index + 'file'"
        :border="false"
      >
        <div class="img-box">
          <div @click="noticeFile(items)">
            {{ items.fileName }}
          </div>
        </div>
      </van-cell>
      <van-cell v-if="oldFileList.length <= 0 && type == 'edit'">
        <div>暂无附件</div>
      </van-cell>
      <!-- 新增 -->
      <van-cell v-if="newFileList.length > 0" :border="false">
        <van-row gutter="20" justify="space-around">
          <van-col
            span="8"
            class="img-box"
            v-for="(item, index) in newFileList"
            :key="index"
            v-show="imageType.test(item.fileExtension)"
          >
            <van-image
              width="70"
              height="70"
              :src="item.content"
              @click="showPreview"
            >
              <template v-if="type == 'add'">
                <div class="img-delete" @click.stop="newFileDelete(index)">
                  删除
                </div>
              </template>
            </van-image>
            <!-- <van-field
            v-model="item.remark"
            placeholder="请输入附件备注,将以文件名形式存在"
            maxlength="20"
            style="flex-base: calc(100% - 70px)"
          /> -->
          </van-col>
        </van-row>
      </van-cell>
      <van-cell v-show="newFileList.length > 0" :border="false">
        <van-col
          v-for="(items, index) in newFileList"
          :key="index + 'file'"
          span="8"
          class="img-box"
          v-show="fileType.test(items.fileExtension)"
        >
          <div @click="noticeFile(items)">
            {{ items.fileName }}
          </div>
          <template v-if="type == 'add'">
            <div class="file-delete" @click.stop="newFileDelete(index)">
              删除
            </div></template
          >
        </van-col>
      </van-cell>

      <van-uploader
        :border="false"
        v-if="type == 'add'"
        v-model="files"
        :after-read="afterRead"
        :max-count="9"
        :max-size="10 * 1024 * 1024"
        @oversize="oversize"
        accept=".png, .jpg, .jpeg ,.pdf"
        :before-read="beforeRead"
      >
      </van-uploader>
    </van-cell-group>
  </div>
</template>

<script>
import { ImagePreview, Toast } from "vant";

export default {
  components: {},
  props: {
    uuid: {
      type: String,
      default: "10a95f034bb442ef",
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      files: [],
      oldFileList: [],
      newFileList: [],
      oldFileDeleteIds: [],
      imageType: /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/,
      fileType: /\.(ppt|pptx|xlsx|xls|doc|pdf|docx)$/,
      uploadType: [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/peng",
        "image/gif",
        // "application/msword",
        // "application/vnd.ms-excel",
        // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
      ],
    };
  },
  computed: {
    fileList() {
      return [...this.oldFileList, ...this.newFileList];
    },
  },
  watch: {
    uuid: {
      handler(nv) {
        if (nv) {
          this.getFilesByUUID();
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    // 原有文件点击
    // oldFileClick() {
    //   Toast.fail("已提交附件不可修改备注，请删除后重新上传");
    // },
    // 原有文件删除
    oldFileDelete(id, index) {
      this.oldFileDeleteIds.push(id);
      this.oldFileList.splice(index, 1);
    },
    // 新增文件删除
    newFileDelete(index) {
      this.newFileList.splice(index, 1);
      this.files.splice(index, 1);
    },
    // 提交
    onSubmit() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        await this.deleteOldFiles().catch((err) => {
          Toast.fail(`"删除原有表格附件失败:${err.mgs}"`);
          return reject(err.data);
        });
        await this.addNewFiles().catch((err) => {
          Toast.fail(`新增表格附件失败:${err.msg}`);
          return reject(err.data);
        });
        resolve();
      });
    },
    // 添加新增文件
    addNewFiles() {
      return new Promise((resolve, reject) => {
        if (this.newFileList.length) {
          const formData = new FormData();
          for (let i = 0; i < this.newFileList.length; i++) {
            if (this.newFileList[i].remark) {
              const tempFile = this.newFileList[i];
              const file = new File(
                [tempFile.file],
                tempFile.remark + tempFile.fileExtension,
                { type: tempFile.file.type, size: tempFile.file.size }
              );
              formData.append("file", file);
            } else {
              formData.append("file", this.newFileList[i].file);
            }
          }
          formData.append("uuid", this.uuid);
          this.$api.common
            .postUploadUuid(formData)
            .then((res) => {
              if (res.data.state == 0) {
                resolve();
              } else {
                reject(res.data);
              }
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve();
        }
      });
    },
    // 删除原有文件
    deleteOldFiles() {
      return new Promise((resolve, reject) => {
        if (this.oldFileDeleteIds.length) {
          let data = "";
          for (let i = 0; i < this.oldFileDeleteIds.length; i++) {
            if (i == this.oldFileDeleteIds.length - 1) {
              data += "ids=" + this.oldFileDeleteIds[i];
            } else {
              data += "ids=" + this.oldFileDeleteIds[i] + "&";
            }
          }
          this.$api.common
            .getDeletesMinioFiles(data)
            .then((res) => {
              if (res.data.state == 0) {
                resolve();
              } else {
                reject();
              }
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve();
        }
      });
    },
    // 图片预览
    showPreview(e) {
      ImagePreview({ images: [e.target.src], showIndex: false });
    },
    // 图片上传完成
    afterRead(file) {
      if (file instanceof Array) {
        file.forEach((item) => {
          this.checkFileList(item);
        });
      } else {
        this.checkFileList(file);
      }
    },
    checkFileList(file) {
      const fileName = file.file.name.substring(
        0,
        file.file.name.lastIndexOf(".")
      );
      const fileExtension = file.file.name.substring(
        file.file.name.lastIndexOf(".")
      );
      this.newFileList.push({
        ...file,
        fileName,
        fileExtension,
        remark: "",
      });
    },
    // 事件提醒
    noticeFile() {
      Toast.fail("不支持在线查看，请到PC端下载");
    },
    // 通过uuid获取附件
    getFilesByUUID() {
      const data = {
        uuid: this.uuid,
      };
      this.$api.common.getFileByUuid(data).then((res) => {
        if (res.data.state == 0) {
          // this.oldFileList = res.data.data || [];
          const _data = res.data.data || [];
          this.oldFileList = _data.map((item) => {
            return {
              ...item,

              remark: item.fileName.substring(
                0,
                item.fileName.lastIndexOf(".")
              ),
            };
          });
        }
      });
    },
    // 上传前置处理
    beforeRead(file) {
      if (Array.isArray(file)) {
        // 判断是否是数组
        for (let i = 0; i < file.length; i++) {
          if (!this.uploadType.includes(file[i].type)) {
            Toast("请上传图片或附件");
            return false;
          }
        }
        // file.forEach(item => {
        //   if (!this.uploadType.includes(item.type)) {
        //     Toast("请上传图片或附件");
        //     return false;
        //   }
        // });
      } else {
        if (!this.uploadType.includes(file.type)) {
          Toast("请上传图片或附件");
          return false;
        }
      }
      return true;
    },
    // 附件上传大小超标
    oversize() {
      Toast("文件大小不能超过10M");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-uploader__preview {
  display: none;
}
.img-box {
  display: flex;
  align-items: center;
  .van-field {
    flex-basis: calc(100% - 70px);
  }
  .van-image {
    margin-top: 10px;
  }
  /deep/.img-delete {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    // padding: 4px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    background: rgba(255, 0, 0, 0.5);
  }
  /deep/.file-delete {
    position: absolute;
    right: 0;
    box-sizing: border-box;
    width: 13%;
    color: #fff;
    font-size: 0.625rem;
    text-align: center;
    background: rgba(255, 0, 0, 0.5);
  }
}
</style>
